<template>
    <section>
        <TitleBar title="Satınalma Siparişi Detayları" :modi="modi" />

        <b-tabs>
            <b-tab-item class="columns is-multiline" label="Genel">
                <b-field class="column is-6 box" label="Tanım" grouped group-multiline>
                    <b-field class="column is-12" label="ID">
                        <b-input v-model="purchaseorder.id" disabled></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Açıklama">
                        <b-input v-model="purchaseorder.name" :disabled="modi == 'show'"></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Tedarikçi Unvan">
                        <CustomerAutofill v-model="purchaseorder.customer" :disabled="modi == 'show'" />
                    </b-field>
                </b-field>

                <b-field class="column is-6 box" label="Durum" grouped group-multiline>
                    <b-radio-button class="column is-6" type="is-danger is-light" size="is-small" native-value="Açık" v-model="purchaseorder.status" :disabled="modi == 'show'" expanded>
                        Açık
                    </b-radio-button>
                    <b-radio-button class="column is-6" type="is-warning is-light" size="is-small" native-value="Askıda" v-model="purchaseorder.status" :disabled="modi == 'show'" expanded>
                        Askıda
                    </b-radio-button>
                    <b-radio-button class="column is-6" type="is-success is-light" size="is-small" native-value="Kapalı" v-model="purchaseorder.status" :disabled="modi == 'show'" expanded>
                        Kapalı
                    </b-radio-button>
                    <b-radio-button class="column is-6" type="is-light" size="is-small" native-value="İptal" v-model="purchaseorder.status" :disabled="modi == 'show'" expanded>
                        İptal
                    </b-radio-button>
                </b-field>

                <b-field class="column is-6 box" label="Notlar" grouped group-multiline>
                    <b-input class="column is-12" type="textarea" v-model="purchaseorder.note" :rows="5" :disabled="modi == 'show'"></b-input>
                </b-field>

                <b-field class="column is-6 box" label="Sipariş Bilgileri" grouped group-multiline>
                    <b-field class="column is-6" label="Ödeme Türü">
                        <b-select placeholder="Seçiniz.." v-model="purchaseorder.paymtype" :disabled="modi == 'show'" expanded>
                            <option value="Vadeli">Vadeli</option>
                            <option value="Çek">Çek</option>
                            <option value="Peşin">Peşin</option>
                            <option value="Diğer">Diğer</option>
                        </b-select>
                    </b-field>
                    <b-field class="column is-6" label="Teslimat Türü">
                        <b-select placeholder="Seçiniz.." v-model="purchaseorder.shiptype" :disabled="modi == 'show'" expanded>
                            <option value="Alıcı Ödemeli">Alıcı Ödemeli</option>
                            <option value="Gönderici Ödemeli">Gönderici Ödemeli</option>
                            <option value="Diğer">Diğer</option>
                        </b-select>
                    </b-field>
                    <b-field class="column is-12" label="Düzenleme Tarihi">
                        <b-datetimepicker size="is-small" icon="calendar-today" placeholder="Seçiniz.." v-model="purchaseorder.issuedat" :timepicker="{ hourFormat: '24' }" :disabled="modi == 'show'" horizontal-time-picker rounded> </b-datetimepicker>
                    </b-field>
                    <b-field class="column is-9" label="Vade Tarihi">
                        <b-datetimepicker size="is-small" icon="calendar-today" placeholder="Seçiniz.." v-model="purchaseorder.expiryat" :timepicker="{ hourFormat: '24' }" :disabled="modi == 'show'" horizontal-time-picker rounded> </b-datetimepicker>
                    </b-field>
                    <b-field class="column is-3" label="Gün">
                        <b-numberinput type="is-link is-light" controls-position="compact" v-model="expiryday" :min-step="1" :disabled="modi == 'show'" expanded></b-numberinput>
                    </b-field>
                    <b-field class="column is-12" label="Termin Tarihi">
                        <b-datetimepicker size="is-small" icon="calendar-today" placeholder="Seçiniz.." v-model="purchaseorder.terminat" :timepicker="{ hourFormat: '24' }" :disabled="modi == 'show'" horizontal-time-picker rounded> </b-datetimepicker>
                    </b-field>
                </b-field>

                <b-field class="column is-12 box" label="Satınalma Siparişi Kalemleri Tablosu" grouped group-multiline>
                    <b-field class="column is-12" position="is-right" grouped group-multiline>
                        <b-button class="column" type="is-success is-light" icon-left="plus" label="Ekle" @click="bom_add" :disabled="modi == 'show'" />
                        <b-button class="column ml-1" type="is-warning is-light" icon-left="import" label="Temizle" disabled />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Yazdır" />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" />
                    </b-field>

                    <b-table class="column is-12" :key="$store.state.ui.flush" :data="purchaseorder.boms" :mobile-cards="false" striped narrowed>
                        <template slot="empty">
                            <div class="is-12 has-text-centered">
                                Satınalma siparişi kalem kaydı bulunamadı.
                            </div>
                        </template>

                        <b-table-column field="id" label="ID" v-slot="props" sortable>{{ props.row.id.slice(-5) }}</b-table-column>
                        <b-table-column field="item.code" label="Malzeme Kodu" v-slot="props" sortable>
                            <MaterialAutofill v-model="props.row.item" :disabled="!!props.row.id" />
                        </b-table-column>
                        <b-table-column field="item.name" label="Malzeme Açıklaması" v-slot="props" sortable>{{ props.row.item.name }}</b-table-column>
                        <b-table-column field="quantity" label="Miktar (KG)" v-slot="props" sortable>
                            <b-numberinput type="is-link is-light" placeholder="0.00" controls-alignment="right" controls-position="compact" v-model="props.row.quantity" :min-step="0.001" :disabled="modi == 'show'" expanded></b-numberinput>
                        </b-table-column>
                        <b-table-column field="price" label="Birim Fiyat" v-slot="props" sortable>
                            <div class="columns">
                                <b-select class="is-2" v-model="props.row.currency" placeholder="Birim">
                                    <option value="USD">$</option>
                                    <option value="EUR">€</option>
                                    <option value="TRY">₺</option>
                                    <option value="GBP">£</option>
                                </b-select>
                                <b-numberinput class="is-10" type="is-link is-light" placeholder="0.00" controls-alignment="right" controls-position="compact" v-model="props.row.price" :min-step="0.0001" :disabled="modi == 'show'"></b-numberinput>
                            </div>
                        </b-table-column>
                        <b-table-column field="itemsum" label="Kalem Toplam" v-slot="props" numeric sortable>
                            {{ (props.row.currency || "") + " " + (props.row.price * props.row.quantity).toFixed(2).toString() }}
                        </b-table-column>
                        <b-table-column field="action" label="Aksiyon" v-slot="props"> <b-button type="is-danger is-light" icon-left="delete" @click="bom_delete(props.row.id, props.index)" :disabled="modi == 'show'"/></b-table-column>
                    </b-table>
                </b-field>
            </b-tab-item>

            <b-tab-item label="Kayıt Geçmişi" :disabled="!purchaseorder.id">
                <MetaDetails v-if="$route.query.id" />
            </b-tab-item>
        </b-tabs>

        <b-field position="is-right">
            <b-button class="ml-1" type="is-success is-light" icon-left="check" label="Kaydet" :disabled="modi == 'show'" @click="purchaseorder_save" />
            <b-button class="ml-1" type="is-danger is-light" icon-left="close" label="Iptal" @click="purchaseorder_cancel" />
        </b-field>
    </section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";
import MaterialAutofill from "@/components/MaterialAutofill.vue";
import CustomerAutofill from "@/components/CustomerAutofill.vue";
import MetaDetails from "@/components/MetaDetails.vue";

export default {
    name: "PurchaseOrderDetails",
    components: {
        TitleBar,
        MaterialAutofill,
        CustomerAutofill,
        MetaDetails,
    },
    data: () => ({
        modi: "",
        expiryday: 0,
        purchaseorder: {
            id: "",
            customer: {},
            boms: [],
        },
    }),
    created() {
        this.purchaseorder.id = this.$route.query.id || "";
        this.modi = this.$route.query.modi || "add";
        this.purchaseorder_read();
    },
    methods: {
        purchaseorder_read() {
            if (this.modi == "add") return;
            this.$apollo.queries.readPurchaseOrder.start();
        },
        purchaseorder_save() {
            var err = "";
            var payload = this.purchaseorder;

            if (!payload.name) err = "Açıklama boş olamaz.";
            if (!payload.status) err = "Durum boş olamaz.";
            if (!payload.customer.id) err = "Tedarikçi boş olamaz.";
            if (!payload.paymtype) err = "Ödeme türü boş olamaz.";
            if (!payload.shiptype) err = "Teslimat türü boş olamaz.";
            if (!payload.expiryat) err = "Vade tarihi boş olamaz.";
            if (!payload.terminat) err = "Termin tarihi boş olamaz.";
            if (!payload.issuedat) err = "Düzenleme tarihi boş olamaz.";

            if (err)
                return this.$buefy.snackbar.open({
                    type: "is-warning",
                    message: err,
                    position: "is-bottom-right",
                });

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation($purchaseorder: String) {
                            savePurchaseOrder(purchaseorder: $purchaseorder)
                        }
                    `,
                    variables: {
                        purchaseorder: JSON.stringify(payload),
                    },
                })
                .then(() => {
                    this.$buefy.snackbar.open({
                        type: "is-success",
                        message: "Başarılı",
                        position: "is-bottom-right",
                    });

                    return this.$router.push({ path: "/satinalma/siparis/liste" });
                })
                .catch(() => {
                    return this.$buefy.snackbar.open({
                        type: "is-danger",
                        message: "Bir hata oluştu.",
                        position: "is-bottom-right",
                    });
                });
        },
        purchaseorder_cancel() {
            this.$router.push({ path: "/satinalma/siparis/liste" });
        },
        bom_add() {
            this.purchaseorder.boms.push({ id: "", item: {}, quantity: 0.0 });
        },
        bom_delete(id, index) {
            if (!id) this.purchaseorder.boms.splice(index, 1);
            else;
        },
    },
    apollo: {
        readPurchaseOrder: {
            query: gql`
                query readPurchaseOrders($config: String) {
                    readPurchaseOrders(config: $config) {
                        id
                        name
                        status
                        customer {
                            id
                            name
                        }
                        paymtype
                        shiptype
                        issuedat
                        expiryat
                        terminat
                        note
                        boms {
                            id
                            item {
                                id
                                code
                                name
                            }
                            price
                            currency
                            quantity
                        }
                    }
                }
            `,
            variables() {
                return {
                    config: JSON.stringify({
                        skip: 0,
                        take: 1,
                        sorts: [],
                        filters: { id: this.purchaseorder.id },
                    }),
                };
            },
            update(data) {
                this.$apollo.queries.readPurchaseOrder.stop();
                this.purchaseorder = data.readPurchaseOrders[0];
                this.purchaseorder.terminat = new Date(this.purchaseorder.terminat);
                this.purchaseorder.expiryat = new Date(this.purchaseorder.expiryat);
                this.purchaseorder.issuedat = new Date(this.purchaseorder.issuedat);

                if (this.modi == "ref") {
                    delete this.purchaseorder.id;
                    for (var bom of this.purchaseorder.boms) delete bom.id;
                }
            },
        },
    },
    watch: {
        expiryday: function() {
            var newdate = new Date(this.purchaseorder.issuedat);
            newdate.setDate(newdate.getDate() + this.expiryday);
            this.purchaseorder.expiryat = newdate;
        },
    },
};
</script>
